import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import Switch from "react-switch";

const FoodItemEdit = () => {

    let { generalSettings } = useContext(SettingsContext)

    let {
        //dept-tag
        // getDeptTag,
        // deptTagList,
        // setDeptTagList,
        // setPaginatedDeptTag,
        deptTagForSearch,
      } = useContext(RestaurantContext);
    

    let {
        //common
        loading,
        setLoading,
    
        //foodGroup
        foodGroupForSearch,
    
        //property group
        propertyGroupForSearch,
    
        setFoodForSearch,
    
        //variations
        variationForSearch,
        // getFoodItemsForPOS,
        //pagination
        dataPaginating,
      } = useContext(FoodContext);

    const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const menuWisePrice = useRef(0);
  let [costForVariations, setCostForVariations] = useState(null);
  let [priceForVariations, setPriceForVariations] = useState(null);
  let [statusForVariations, setStatusForVariations] = useState(null);
  let [priceForItem, setPriceForItem] = useState({});
  let [selectedMenu, setSelectedMenu] = useState([]);
  let [priceForMenuVariation, setPriceForMenuVariation] = useState({});
   //new item

   let [newItem, setNewItem] = useState({
    itemGroup: null,
    sku: "",
    name: "",
    item_description: "",
    price: 0,
    cost_price: 0,
    cost_price_percent: 0,
    image: null,
    itemId:null,
    hasProperty: false,
    properties: null,
    hasVariation: false,
    isSpecial: false,
    isDeal : false,
    variations: null,
    status: true,

  });      
  
   useEffect(()=>{

     getFoodItem();
    
     if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
       menuWisePrice.current = 1
      }else{
       menuWisePrice.current = 0
      }
  
   },[generalSettings])



    const getFoodItem = async () => {
        setLoading(true)
     const url =
        BASE_URL +
        "/settings/food-item-by-id/" +
        parseInt(params.id);
      try {
         const res = await axios
         .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
         });
         menuWisePrice.current = parseInt(res.data[0].menu_wise_rate)

         let Foodgroup = JSON.parse(res.data[0].food_groups) ? JSON.parse(res.data[0].food_groups).map((group)=>{
           return foodGroupForSearch.find((item)=> item.id === group)
          }) : null;
          let deptTag = JSON.parse(res.data[0].menu_ids) ? JSON.parse(res.data[0].menu_ids).map((dept)=>{
            return deptTagForSearch.find((deptId)=> deptId.id === dept)
          }) : null;
          let propertyGroup = JSON.parse(res.data[0].property_groups) ? JSON.parse(res.data[0].property_groups).map((property)=>{
            return propertyGroupForSearch.find((propItem)=> propItem.id === property)
          }) : null;
         setSelectedMenu(deptTag);

          let variation = [];
          if(menuWisePrice.current){
            if(res.data[0].has_variation === "1"){
              let data = res.data[0].variations;
              let updateVariationPrice = {};
              let updateVariationCostPrice = {};
              let updateVariationStatus = {};
              let menuVariation = {}
              Object.keys(data).forEach((menuId)=>{
                const variationArray = [];

                Object.keys(data[menuId]).forEach((item)=>{
                  // Add items to the array (you can add whatever you need here)
                  variationArray.push({id: parseInt(item), name: data[menuId][item].name });
                  updateVariationPrice[menuId] = {
                    ...updateVariationPrice[menuId],
                    [item] : data[menuId][item].price
                  }
                  updateVariationCostPrice[menuId] = {
                    ...updateVariationCostPrice[menuId],
                    [item] : data[menuId][item].cost_price
                  }
                  updateVariationStatus[menuId] = {
                    ...updateVariationStatus[menuId],
                    [item] : data[menuId][item].status === 1 ? true : false
                  }
                
                  menuVariation[menuId] = variationArray;
                 
                  if(Object.keys(data)[0] === menuId){
                    variation.push({
                     id: item,
                     name: data[menuId][item].name
                    })
                   }
                  })
                })
                setCostForVariations(updateVariationCostPrice);
                setPriceForVariations(updateVariationPrice)
                setStatusForVariations(updateVariationStatus)
                setPriceForMenuVariation(menuVariation)
            }else{
              let updatePrice = {};
              res.data[0].price.forEach((price)=>{
                updatePrice[price.menu_id] = {
                  menu_id: price.menu_id,
                  menu_name: price.menu_name,
                  price: price.price
                }
              })
              setPriceForItem(updatePrice)
            }
          }else{
            if(res.data[0].has_variation === "1"){
              res.data[0].variations.forEach((variationItem)=>{
                setCostForVariations((curr)=>({
                ...curr,
                [variationItem.id]: variationItem.cost_price
                }));
                setPriceForVariations((curr)=>({
                ...curr,
                [variationItem.id]: variationItem.price
                }));
              })
            }
          }
          setNewItem({
              itemGroup: Foodgroup,
              sku: res.data[0].sku ? res.data[0].sku : "",
              name: res.data[0]?.name,
              item_description: res.data[0].item_description ? res.data[0].item_description : "",
              price: res.data[0].price ? res.data[0].price : 0,
              cost_price: res.data[0].cost_price ? parseFloat(res.data[0].cost_price).toFixed(2) : 0,
              cost_price_percent: res.data[0].cost_price_percent ? res.data[0].cost_price_percent : 0,
              image: null,
              itemId: res.data[0].id,
              hasProperty: res.data[0].has_property === "0" ? false : true,
              properties: propertyGroup,
              hasVariation: res.data[0].has_variation === "0" ? false : true,
              isSpecial: res.data[0].is_special === "0" ? false : true,
              isDeal: res.data[0].is_deal === "0" ? false : true,
              variations: menuWisePrice.current ? variation : res.data[0].variations ? res.data[0].variations : null,
              status: res.data[0].item_status ? true : false,
          });
          setLoading(false);
        } catch {
          setLoading(false);
        }
    };

    const handleSelectedMenu = (menu) => {
      // setNewParty({ ...newParty, menu });
      setSelectedMenu(menu);
     if(menuWisePrice.current === 1){ 
        const updatedPriceForVariation = {};
        const updatedPriceForItem = {};
        let updatePriceVariation= {}
        menu.forEach((menuItem)=>{
          updatedPriceForVariation[menuItem.id] = newItem.variations          
          priceForMenuVariation[menuItem?.id] && priceForMenuVariation[menuItem?.id].forEach((variationItem)=>{
            updatePriceVariation[menuItem?.id] = { 
              ...updatePriceVariation[menuItem?.id], 
             [variationItem.id]: (priceForVariations && priceForVariations[menuItem?.id] && priceForVariations[menuItem?.id][variationItem?.id]) ? priceForVariations[menuItem?.id][variationItem?.id] : ""
           }
          })
          updatedPriceForItem[menuItem.id] = {
            price: priceForItem && priceForItem[menuItem?.id] ? priceForItem[menuItem?.id].price : "",
            menu_name: menuItem.name,
            menu_id : menuItem.id
          }
        })
        setPriceForVariations(updatePriceVariation)
        setPriceForMenuVariation(updatedPriceForVariation);
        setPriceForItem(updatedPriceForItem);
      }
    };
 
     //set variations hook
  const handleSetVariations = (variations) => {
    if(menuWisePrice.current){
      let variation = {}
      let updatePriceVariation = {}
      let updateStatusVariation = {}
      selectedMenu.forEach((menu)=>{
        variation[menu.id] = variations;
         variations && variations.forEach((variation)=>{
            updatePriceVariation[menu?.id] = { 
               ...updatePriceVariation[menu?.id], 
              [variation.id]: priceForVariations && priceForVariations[menu?.id] && priceForVariations[menu?.id][variation?.id] ? priceForVariations[menu?.id][variation.id] : ""
            }
            updateStatusVariation[menu?.id] = { 
              ...updateStatusVariation[menu?.id], 
              [variation.id]: updateStatusVariation && updateStatusVariation[menu] && updateStatusVariation[menu][variation.id] ? updateStatusVariation[menu][variation.id] : true ,
            }
        });
      })
      setPriceForMenuVariation(variation);
      setPriceForVariations(updatePriceVariation);
      setStatusForVariations(updateStatusVariation)
    }else{
      let updatePriceVariations = {}
      let updateStatusVariation = {}
       variations && variations.forEach((variation)=>{
          updatePriceVariations[variation?.id] = priceForVariations && priceForVariations[variation?.id] ? priceForVariations[variation?.id] : "";
          updateStatusVariation[variation?.id] = updateStatusVariation && updateStatusVariation[variation?.id] ? updateStatusVariation[variation?.id] : true;
      });
      setPriceForVariations(updatePriceVariations);
      setStatusForVariations(updateStatusVariation)
    }
    setNewItem({ ...newItem, variations });
    
  };

     //has variations
  const handleVariationCheckboxChange = (e) => {
    if (newItem.hasVariation === true) {
      setNewItem({
        ...newItem,
        variations: null,
        hasVariation: !newItem.hasVariation,
      });
    } else {
      setNewItem({ ...newItem, hasVariation: !newItem.hasVariation });
    }
  };
   //has Property
   const handlePropertyCheckboxChange = (e) => {
    if (newItem.hasProperty === true) {
      setNewItem({
        ...newItem,
        properties: null,
        hasProperty: !newItem.hasProperty,
      });
    } else {
      setNewItem({ ...newItem, hasProperty: !newItem.hasProperty });
    }
  };  
     //set properties hook
  const handleSetPropertes = (properties) => {
    setNewItem({ ...newItem, properties });
  };

  
  //set status variation
  const handleVariationStatus = (variationId, menu = null)=>{
    if(menuWisePrice.current){
      let updateStatusVariation = {...statusForVariations}
      updateStatusVariation[menu] = {
        ...updateStatusVariation[menu], 
        [variationId]: updateStatusVariation && updateStatusVariation[menu] && updateStatusVariation[menu][variationId] ? !updateStatusVariation[menu][variationId] : true,
      }
      setStatusForVariations(updateStatusVariation);
    }else{
      setStatusForVariations({
        ...statusForVariations,
        [variationId]: statusForVariations && statusForVariations[variationId] ? !statusForVariations[variationId] : true,
      });
    }
  }
    //set each variation price
  const handleVariationPrice = (e, menu = null ) => {
    if(menuWisePrice.current){
      let updatePriceVariation = {...priceForVariations}
      updatePriceVariation[menu] = { 
        ...updatePriceVariation[menu], 
        [e.target.name]: e.target.value,
      }
      setPriceForVariations(updatePriceVariation);
    }else{
      setPriceForVariations({
        ...priceForVariations,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleVariationCost = (e, menu) => {
    const name = e.target.name.replace(/(-cost)/g, '')
    if(menuWisePrice.current){
      let updateCostVariation = {...costForVariations}
       // auto cost price update
      //  selectedMenu.forEach((menu)=>{
      //   updateCostVariation[menu.id] = {
      //     ...updateCostVariation[menu.id],
      //     [name]: e.target.value,
      //   }
      //  })
      updateCostVariation[menu] = {
        ...updateCostVariation[menu], 
        [name]: e.target.value,
      }
      setCostForVariations(updateCostVariation);
    }else{
    setCostForVariations({
      ...costForVariations,
      [name]: e.target.value,
    });
   }
  };

  //is special
  const handleSpecialCheckboxChange = (e) => {
    setNewItem({
      ...newItem,
      isSpecial: !newItem.isSpecial,
    });
  };
  //is deal 
  const handleDealCheckboxChange = (e) => {
    setNewItem({
      ...newItem,
      isDeal: !newItem.isDeal,
    });
  };
   //set image hook
   const handleItemImage = (e) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleStatus = (e) => {
    setNewItem({
      ...newItem,
      status: !newItem.status
    })
  }

   //on change input field
   const handleChange = (e) => {
    const key = Object.keys(priceForItem)[0]
    if (e.target.name === "cost_price") {

      const price = menuWisePrice.current ? priceForItem[key].price : newItem.price;
      let cost_price = parseFloat(e.target.value) > parseFloat(price) ? price : e.target.value;
      if (price === "") {
        cost_price = 0;
      } else {
          let  generate_percent =
          (parseInt(cost_price) / parseInt(price)) * 100;
        
        setNewItem({
          ...newItem,
          cost_price_percent: generate_percent,
          cost_price: cost_price,
        });
      }
    } else if (e.target.name === "cost_price_percent") {

      let cost_price_percent = parseFloat(e.target.value) > 100 ? 100 : e.target.value;
      const price = menuWisePrice.current ? priceForItem[key].price : newItem.price;
      if (price === "") {
        cost_price_percent = 0;
      } else {
        let generate_amount =
          (parseInt(price) / 100) * parseInt(cost_price_percent);
        setNewItem({
          ...newItem,
          cost_price_percent: cost_price_percent,
          cost_price: generate_amount,
        });
      }
    } else {
      setNewItem({ ...newItem, [e.target.name]: e.target.value });
    }
  };

    //on change menu wise price
    const handleMenuPrice = (e,key) =>{
      setPriceForItem({
        ...priceForItem,
        [key]:{
          ...priceForItem[key],
          [e.target.name]: e.target.value
        }
      })
    }

   //handle Set item group hook
   const handleSetItemGroup = (itemGroup) => {
    let group = [itemGroup];
    setNewItem({...newItem, itemGroup: group});
  };
 
   //post req of food item add
  const handleFoodItemUpdate = (e) => {
     e.preventDefault();
     if(selectedMenu == null || selectedMenu?.length === 0){
      toast.error(`${_t(t("Please select menu group"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }

    if(newItem.itemGroup === null){
      toast.error(`${_t(t("Please select food group"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }

    if (newItem.hasProperty === true && (newItem.properties === null || newItem.properties?.length === 0)) {
      toast.error(`${_t(t("Please select properties"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }
    if (newItem.hasVariation === true && (newItem.variations === null)) {
      toast.error(`${_t(t("Please select variations"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }
    setLoading(true);

    let formData = new FormData();
    //formData.append("food_group_id", newItem.itemGroup.id);
    formData.append("name", newItem.name);
    formData.append("itemId", newItem.itemId);
    formData.append("item_description", newItem.item_description);
    formData.append("sku", newItem.sku);
    formData.append("hasProperty", newItem.hasProperty === true ? 1 : 0);
    if (newItem.hasProperty === true) {
      formData.append("hasProperty", 1);
      let tempArray = [];
      newItem.properties.map((pItem) => {
        tempArray.push(pItem.id);
      });
      formData.append("properties", tempArray);
    }

    formData.append("hasVariation", newItem.hasVariation === true ? 1 : 0);
    formData.append("isSpecial", newItem.isSpecial === true ? 1 : 0);
    formData.append("is_deal", newItem.isDeal === true ? 1 : 0);

    formData.append("item_status", newItem.status === true ? 1 : 0);
    formData.append("menu_wise_price", menuWisePrice.current ? 1 : 0);

    if(menuWisePrice.current){
      if (newItem.hasVariation === false) {
        formData.append("menu_price", JSON.stringify(priceForItem));
        formData.append("cost_price", newItem.cost_price ? newItem.cost_price : 0);
        formData.append("cost_price_percent", newItem.cost_price_percent ? newItem.cost_price_percent : 0);
      } else {
        // let tempData = Object.entries(priceForVariations);
        // tempData.map((item) => {
        //   formData.append("variations[]", item);
        // });
        let variationObj = {}
        const tempKeys = priceForVariations && Object.keys(priceForVariations);

        tempKeys && tempKeys.forEach((key) => {
          Object.keys(priceForVariations[key]).map((keys)=>{
            let price =  priceForVariations[key][keys];
            let costVariation =  costForVariations[key] && costForVariations[key][keys];
            let cost = (costForVariations && costForVariations[key] && costForVariations[key][keys]) ? costVariation : "0";
            let status = (statusForVariations && statusForVariations[key] && statusForVariations[key][keys]) ? 1 : 0 
            variationObj[key] = {
              ...variationObj[key],
              [keys] : {
                cost_price : cost,
                price : price,
                status: status,
                menu_id : key,
              }
            }
          })
        })
        const payloadString = JSON.stringify(variationObj);
        formData.append("variations", payloadString);
      }
     }else{
      if (newItem.hasVariation === false) {
        formData.append("price", newItem.price);
        formData.append("cost_price", newItem.cost_price ? newItem.cost_price : 0);
        formData.append("cost_price_percent", newItem.cost_price_percent ? newItem.cost_price_percent : 0);
      } else {
        //converting variations and prices to array
        console.log("1111111111newitem ", newItem, priceForVariations);
        // let slugArray = [];
        // newItem.variations.map((newVarItem) => {
        //   slugArray.push(newVarItem.id);
        // });
        // slugArray.map((slugItem) => {
        //   formData.append("slugOfVariations[]", slugItem);
        // });
      
      let variationObj = {}
        const tempKeys = priceForVariations && Object.keys(priceForVariations)
        tempKeys?.length > 0 && tempKeys.forEach((key) => {
          const price = priceForVariations[key];
          const cost = costForVariations && costForVariations[key] ? costForVariations[key] : "0";
          const status = statusForVariations && statusForVariations[key] ? 1 : 0
          //formData.append("variations[]", [key, price, cost]);
          variationObj[key] = { price, cost, status };
        });
        const payloadString = JSON.stringify(variationObj);
        formData.append("variations", payloadString);
      }
    }
   
    formData.append("image", newItem.image);
    //for food_group
    let foodArray = [];
    newItem.itemGroup.map((Item) => {
      foodArray.push(Item.id);
    });
    
    formData.append("food_group_id", foodArray.toString());
    //for menu_group
    let tempArray = [];
    selectedMenu.map((Item) => {
      tempArray.push(Item.id);
    });
    formData.append("menu_ids", tempArray.toString());
    
    const url = BASE_URL + "/settings/update-food-item-all";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodForSearch(res.data[1]);
        setNewItem({
          itemGroup: null,
          sku: "",
          name: "",
          item_description: "",
          price: "",
          cost_price: "",
          image: null,
          itemId: null,
          hasProperty: false,
          properties: null,
          hasVariation: false,
          isSpecial: false,
          isDeal: false,
          variations: null,
          status: true,
        });
        setSelectedMenu([]);
        setPriceForMenuVariation({})
        setPriceForItem({});
        setPriceForVariations(null)
        setCostForVariations(null);
        // getFoodItemsForPOS();
        history.push("/dashboard/manage/food/all-items")
        setLoading(false);
        toast.success(`${_t(t("Food item has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        // if (error.response.data.errors.image) {
        //   error.response.data.errors.image.forEach((item) => {
        //     if (item === "Please select a valid image file") {
        //       toast.error(`${_t(t("Please select a valid image file"))}`, {
        //         position: "bottom-center",
        //         autoClose: 10000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         className: "text-center toast-notification",
        //       });
        //     }
        //     if (item === "Please select a file less than 5MB") {
            
        //     }
        //   });
        // }
      });
  };


  return (
    <>
    <Helmet>
      <title>{_t(t("Edit Item"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {loading === true
                    ? tableLoading()
                    : [
                              // start here
                              <div key="smtp-form">
                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                  <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                    <ul className="t-list fk-breadcrumb">
                                      <li className="fk-breadcrumb__list">
                                        <span className="t-link fk-breadcrumb__link text-capitalize">
                                          {_t(t("Edit item"))}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-6 col-lg-7">
                                    <div className="row gx-3 align-items-center"></div>
                                  </div>
                                </div>

                                {/* Form starts here */}
                                <form
                                  className="row card p-2 mx-3 mb-5 sm-text"
                                  onSubmit={handleFoodItemUpdate}
                                  autoComplete="off"
                                >
                                  <div className="col-12">
                                    {deptTagForSearch && (
                                      <div className="form-group mt-2 mb-2">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="itemGroup"
                                            className="control-label"
                                          >
                                            {_t(t("Menu group"))}
                                            <span className="text-danger mr-1">
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <Select
                                          options={deptTagForSearch}
                                          components={makeAnimated()}
                                          value={selectedMenu}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.id
                                          }
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          maxMenuHeight="200px"
                                          isMulti={true}
                                          closeMenuOnSelect={false}
                                          onChange={handleSelectedMenu}
                                          placeholder={
                                            _t(t("Please select menu")) + ".."
                                          }
                                        />
                                      </div>
                                    )}

                                    {foodGroupForSearch && (
                                      <div className="form-group mt-2">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="itemGroup"
                                            className="control-label"
                                          >
                                            {_t(t("Food group"))}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <Select
                                          options={foodGroupForSearch}
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.id
                                          }
                                          value={newItem.itemGroup}
                                          classNamePrefix="select"
                                          onChange={handleSetItemGroup}
                                          maxMenuHeight="200px"
                                          placeholder={
                                            _t(
                                              t("Please select a food group")
                                            ) + ".."
                                          }
                                        />
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="form-group mt-3 col-md-6">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="name"
                                            className="control-label"
                                          >
                                            {_t(t("Name"))}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="mb-2">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            onChange={handleChange}
                                            value={newItem.name}
                                            placeholder="e.g. Spicy chicken burger"
                                            required
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-3 col-md-6">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="sku"
                                            className="control-label"
                                          >
                                            {_t(t("SKU"))}
                                          </label>
                                        </div>
                                        <div className="mb-2">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="sku"
                                            name="sku"
                                            onChange={handleChange}
                                            value={newItem.sku || ""}
                                            placeholder="e.g. 12345678"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="form-group mt-3 col-md-12">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="item_description"
                                            className="control-label"
                                          >
                                            {_t(t("Item Description"))}
                                          </label>
                                        </div>
                                        <div className="mb-2">
                                          <textarea
                                            type="text"
                                            rows="2"
                                            className="form-control"
                                            id="item_description"
                                            name="item_description"
                                            onChange={handleChange}
                                            value={newItem.item_description}
                                            placeholder="e.g. 1 beef burger, 2 drinks"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-check mt-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="propertyCheck"
                                        checked={newItem.hasProperty}
                                        onChange={
                                          handlePropertyCheckboxChange
                                        }
                                      />
                                      <label
                                        className="form-check-label pointer-cursor"
                                        htmlFor="propertyCheck"
                                      >
                                        {_t(t("Has properties?"))}
                                      </label>
                                    </div>
                                    {propertyGroupForSearch && [
                                      newItem.hasProperty && (
                                        <div className="form-group mt-2 ml-4">
                                          <div className="mb-2">
                                            <label className="control-label">
                                              {_t(t("Add properties"))}
                                            </label>
                                          </div>
                                          <Select
                                            options={propertyGroupForSearch}
                                            components={makeAnimated()}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.id
                                            }
                                            value={newItem.properties}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            closeMenuOnSelect={false}
                                            maxMenuHeight="200px"
                                            onChange={handleSetPropertes}
                                            placeholder={
                                              _t(
                                                t(
                                                  "Please select property groups"
                                                )
                                              ) + ".."
                                            }
                                          />
                                        </div>
                                      ),
                                    ]}

                                    <div className="form-check mt-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="variationCheck"
                                        checked={newItem.hasVariation}
                                        onChange={
                                          handleVariationCheckboxChange
                                        }
                                      />
                                      <label
                                        className="form-check-label pointer-cursor"
                                        htmlFor="variationCheck"
                                      >
                                        {_t(t("Has variations?"))}
                                      </label>
                                    </div>

                                    {newItem.hasVariation &&
                                      variationForSearch && (
                                        <div className="form-group mt-2 ml-4">
                                          <div className="mb-2">
                                            <label className="control-label">
                                              {_t(t("Add variations"))}
                                            </label>
                                          </div>
                                          <Select
                                            options={variationForSearch}
                                            components={makeAnimated()}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.id
                                            }
                                            value={newItem.variations}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            closeMenuOnSelect={false}
                                            maxMenuHeight="200px"
                                            onChange={handleSetVariations}
                                            placeholder={
                                              _t(
                                                t("Please select variations")
                                              ) + ".."
                                            }
                                          />
                                        </div>
                                      )}
                                    {newItem.variations !== null && [
                                      newItem.variations?.length > 0 && (
                                        <div className="card ml-4 mt-3 p-3 custom-bg-secondary">
                                        {
                                          menuWisePrice.current === 0 && (
                                            <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                                              {_t(
                                                t(
                                                  "Please enter price for each variation"
                                                )
                                              )}
                                             </div>
                                          )
                                        }
                                        {
                                          menuWisePrice.current ? (
                                            Object.keys(priceForMenuVariation).map((variationKey)=>{
                                              let menuName = deptTagForSearch.find((menu)=> menu.id == variationKey);
                                              return(
                                               <div className="mt-2">
                                                <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                                                {_t(
                                                  t(
                                                    "Please enter price for each variation for " + menuName.name
                                                  )
                                                )}
                                                </div>
                                                  {
                                                    priceForMenuVariation[variationKey].map((variationItem)=>{
                                                      return (
                                                        <div
                                                        className="row m-0 mt-4"
                                                        key={variationItem.id}
                                                      >
                                                      <div className="form-group col-4 p-0 pr-2">
                                                        <div className="mb-2">
                                                          <label
                                                            htmlFor={
                                                              variationItem.id
                                                            }
                                                            className="control-label sm-text"
                                                          >
                                                            {_t(
                                                              t("Total price of")
                                                            )}{" "}
                                                            <span className="text-primary text-bold">
                                                              {variationItem.name}
                                                            </span>{" "}
                                                            {_t(t("variation"))}
                                                            <span className="text-danger">
                                                              *{" "}
                                                            </span>
                                                           
                                                          </label>
                                                        </div>
                                                        <div className="mb-2">
                                                          <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            className="form-control"
                                                            onKeyDown={(evt) =>
                                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                              evt.preventDefault()
                                                            }
                                                            onWheel={(e) => e.target.blur()} 
                                                            id={variationItem.id}
                                                            name={
                                                              variationItem.id
                                                            }
                                                            value={priceForVariations && priceForVariations[variationKey] && priceForVariations[variationKey][variationItem?.id]}
                                                            onChange={
                                                             (e) => handleVariationPrice(e,variationKey)
                                                            }
                                                            placeholder="e.g. Type price of this item"
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group col-4 p-0 pl-2">
                                                        <div className="mb-2">
                                                          <label
                                                            htmlFor={
                                                              variationItem.id
                                                            }
                                                            className="control-label sm-text"
                                                          >
                                                            {_t(
                                                              t("Total cost of")
                                                            )}{" "}
                                                            <span className="text-primary text-bold">
                                                              {variationItem.name}
                                                            </span>{" "}
                                                            {_t(t("variation "))}
                                                           
                                                          </label>
                                                        </div>
                                                        <div className="mb-2">
                                                          <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            className="form-control"
                                                            onKeyDown={(evt) =>
                                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                              evt.preventDefault()
                                                            }
                                                            onWheel={(e) => e.target.blur()} 
                                                            id={variationItem.id}
                                                            name={
                                                              variationItem.id +
                                                              "-cost"
                                                            }
                                                            value={costForVariations && costForVariations[variationKey] && costForVariations[variationKey][variationItem?.id]}
                                                            onChange={
                                                             (e) => handleVariationCost(e,variationKey)
                                                            }
                                                            placeholder="e.g. Cost Price"
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group col-4 p-0 pl-2">
                                                      <div className="ml-3 mb-2">
                                                        <label
                                                          htmlFor={
                                                            variationItem.id
                                                          }
                                                          className="control-label sm-text"
                                                        >
                                                          {_t(
                                                            t("Status of")
                                                          )}{" "}
                                                          <span className="text-primary text-bold">
                                                            {variationItem.name}
                                                          </span>{" "}
                                                          {_t(t("variation "))}
                                                         
                                                        </label>
                                                      </div>
                                                      <div className="ml-3 mb-2 mt-2">
                                                        {
                                                          /* 
                                                        <Switch
                                                          checked={statusForVariations && statusForVariations[variationKey] && statusForVariations[variationKey][variationItem?.id]}
                                                          id={variationItem.id}
                                                          onChange={(e) => {
                                                            handleVariationStatus(variationItem?.id,variationKey);
                                                          }}
                                                          height={22}
                                                          width={44}
                                                          offColor="#ee5253"
                                                          className="float-left"
                                                         />
                                                         */
                                                        }
                                                         <input
                                                          type="checkbox"
                                                          className="form-check-input mr-1 ml-1 mt-0 cursor-pointer"
                                                          style={{height:"1rem",width:"1rem"}}
                                                          id="status"
                                                          name="status"
                                                          onChange={(e) => {
                                                            handleVariationStatus(variationItem?.id,variationKey);
                                                          }}
                                                          checked={
                                                            statusForVariations && statusForVariations[variationKey] && statusForVariations[variationKey][variationItem?.id]
                                                          }
                                                        />
                                                       </div>
                                                      </div>
                                                      </div>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              )
                                            })
                                             
                                          ) : (
                                            newItem.variations.map(
                                              (variationItem) => {
                                                return (
                                                  <div
                                                    className="row m-0 mt-4"
                                                    key={variationItem.id}
                                                  >
                                                  <div className="form-group col-4 p-0 pr-2">
                                                    <div className="mb-2">
                                                      <label
                                                        htmlFor={
                                                          variationItem.id
                                                        }
                                                        className="control-label sm-text"
                                                      >
                                                        {_t(
                                                          t("Sales price of")
                                                        )}{" "}
                                                        <span className="text-primary text-bold">
                                                          {variationItem.name}
                                                        </span>{" "}
                                                        {_t(t("variation"))}
                                                        <span className="text-danger">
                                                          *{" "}
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div className="mb-2">
                                                      <input
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        className="form-control"
                                                        onWheel={(e) => e.target.blur()} 
                                                        id={variationItem.id}
                                                        name={
                                                          variationItem.id
                                                        }
                                                        value={priceForVariations && priceForVariations[variationItem?.id]}
                                                        onChange={
                                                          handleVariationPrice
                                                        }
                                                        placeholder="e.g. Type price of this item"
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="form-group col-4 p-0 pl-2">
                                                    <div className="mb-2">
                                                      <label
                                                        htmlFor={
                                                          variationItem.id
                                                        }
                                                        className="control-label sm-text"
                                                      >
                                                        {_t(
                                                          t("Cost of")
                                                        )}{" "}
                                                        <span className="text-primary text-bold">
                                                          {variationItem.name}
                                                        </span>{" "}
                                                        {_t(t("variation "))}
                                                      </label>
                                                    </div>
                                                    <div className="mb-2">
                                                      <input
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        className="form-control"
                                                        onWheel={(e) => e.target.blur()} 
                                                        id={variationItem.id}
                                                        name={
                                                          variationItem.id +
                                                          "-cost"
                                                        }
                                                        value={costForVariations && costForVariations[variationItem?.id]}
                                                        onChange={
                                                          handleVariationCost
                                                        }
                                                        placeholder="e.g. Cost Price"
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="form-group col-4 p-0 pl-2">
                                                  <div className="ml-3 mb-2">
                                                    <label
                                                      htmlFor={
                                                        variationItem.id
                                                      }
                                                      className="control-label sm-text"
                                                    >
                                                      {_t(
                                                        t("Status of")
                                                      )}{" "}
                                                      <span className="text-primary text-bold">
                                                        {variationItem.name}
                                                      </span>{" "}
                                                      {_t(t("variation "))}
                                                     
                                                    </label>
                                                  </div>
                                                  <div className="ml-3 mb-2 mt-2">
                                                    {/* 
                                                    <Switch
                                                      checked={statusForVariations && statusForVariations[variationItem?.id]}
                                                      id={variationItem.id}
                                                      onChange={(e) => {
                                                        handleVariationStatus(variationItem?.id);
                                                      }}
                                                      height={22}
                                                      width={44}
                                                      offColor="#ee5253"
                                                      className="float-left"
                                                     />
                                                    */}
                                                     <input
                                                      type="checkbox"
                                                      className="form-check-input mr-1 ml-1 mt-0 cursor-pointer"
                                                      style={{height:"1rem",width:"1rem"}}
                                                      id="status"
                                                      name="status"
                                                      onChange={(e) => {
                                                        handleVariationStatus(variationItem?.id);
                                                      }}
                                                      checked={
                                                        statusForVariations && statusForVariations[variationItem?.id]
                                                      }
                                                    />
                                                   </div>
                                                  </div>
                                                 </div>
                                                );
                                              }
                                            )
                                           )
                                          }
                                        </div>
                                      ),
                                    ]}

                                    {
                                      !newItem.hasVariation && (
                                        <div className="row">
                                          {
                                            menuWisePrice.current ? (
                                            priceForItem &&
                                            Object.keys(priceForItem)?.map((item)=> (
                                              <div className="form-group mt-3 col-md-3">
                                                  <div className="mb-2">
                                                    <label
                                                      htmlFor="price"
                                                      className="control-label table-text"
                                                    >
                                                      {priceForItem[item].menu_name + _t(t(" Price"))}
                                                      <span className="text-danger">
                                                        *{" "}
                                                      </span>
                                                      {/* <small className="text-secondary">
                                                        ({_t(t("Enter price in USD"))})
                                                      </small> */}
                                                    </label>
                                                  </div>
                                                  <div className="mb-2">
                                                    <input
                                                      id="price"
                                                      type="number"
                                                      step="0.01"
                                                      min="0"
                                                      className="form-control"
                                                      onKeyDown={(evt) =>
                                                        ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                        evt.preventDefault()
                                                      }
                                                      onWheel={(e) => e.target.blur()} 
                                                      name="price"
                                                      value={priceForItem[item].price}
                                                      onChange={(e)=>handleMenuPrice(e,item)}
                                                      placeholder="e.g. Type price of this item"
                                                      required
                                                    />
                                                  </div>
                                                </div> 
                                               )) 
                                            ) : (
                                              <div className="form-group mt-4 col-md-6">
                                                <div className="mb-2">
                                                  <label
                                                    htmlFor="price"
                                                    className="control-label"
                                                  >
                                                    {_t(t("Price"))}
                                                    <span className="text-danger">
                                                      *{" "}
                                                    </span>
                                                    {/* <small className="text-secondary">
                                                      ({_t(t("Enter price in USD"))})
                                                    </small> */}
                                                  </label>
                                                </div>
                                                <div className="mb-2">
                                                  <input
                                                    id="price"
                                                    type="number"
                                                    step="0.01"
                                                    min="0"
                                                    className="form-control"
                                                    onKeyDown={(evt) =>
                                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                    onWheel={(e) => e.target.blur()} 
                                                    name="price"
                                                    value={newItem.price}
                                                    onChange={handleChange}
                                                    placeholder="e.g. Type price of this item"
                                                    required
                                                  />
                                                </div>
                                              </div>
                                            )  
                                          }
                                         <div className= "row">
                                          <div className="form-group mt-4 col-md-6">
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="mb-2">
                                                  <label
                                                    htmlFor="cost_price_percent"
                                                    className="control-label"
                                                  >
                                                    {_t(t("Item Cost Percent"))}
                                                  </label>
                                                </div>
                                                <div className="mb-2">
                                                  <input
                                                    id="cost_price_percent"
                                                    type="number"
                                                    max="100"
                                                    className="form-control"
                                                    onKeyDown={(evt) =>
                                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                    onWheel={(e) => e.target.blur()} 
                                                    name="cost_price_percent"
                                                    value={
                                                      newItem.cost_price_percent
                                                    }
                                                    onChange={handleChange}
                                                    placeholder="e.g. 10%"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-2">
                                                  <label
                                                    htmlFor="cost_price"
                                                    className="control-label"
                                                  >
                                                    {_t(t("Item Cost"))}
                                                  </label>
                                                </div>
                                                <div className="mb-2">
                                                  <div class="input-group mb-3">
                                                    <span
                                                      class="input-group-text"
                                                      id="basic-addon1"
                                                    >
                                                      Rs
                                                    </span>
                                                    <input
                                                      id="cost_price"
                                                      type="number"
                                                      //max={newItem.price}
                                                      className="form-control"
                                                      onKeyDown={(evt) =>
                                                        ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                        evt.preventDefault()
                                                      }
                                                      onWheel={(e) => e.target.blur()} 
                                                      name="cost_price"
                                                      value={newItem.cost_price}
                                                      onChange={handleChange}
                                                      placeholder="e.g. 100"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                         </div>
                                        </div>
                                      )
                                    }

                                    <div className="form-check mt-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="specialCheck"
                                        checked={newItem.isSpecial}
                                        onChange={handleSpecialCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label pointer-cursor"
                                        htmlFor="specialCheck"
                                      >
                                        {_t(t("Is Special?"))}
                                      </label>
                                    </div>

                                    <div className="form-check mt-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="dealCheck"
                                        checked={newItem.isDeal}
                                        onChange={handleDealCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label pointer-cursor"
                                        htmlFor="dealCheck"
                                      >
                                        {_t(t("Is Deal?"))}
                                      </label>
                                    </div>
                                    <div className="form-group mt-4">
                                      <div className="mb-2">
                                        <label
                                          htmlFor="image"
                                          className="control-label"
                                        >
                                          {_t(t("Image"))}
                                          {/* <span className="text-danger">
                                            *
                                          </span>{" "} */}
                                          <small className="text-secondary">
                                            ({_t(t("300 x 300 Preferable"))})
                                          </small>
                                        </label>
                                      </div>
                                      <div className="mb-2">
                                        <input
                                          type="file"
                                          className="form-control sm-text"
                                          id="image"
                                          name="image"
                                          onChange={handleItemImage}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-check mt-4 ">
                                      <Switch
                                          checked={newItem.status}
                                          name="status"
                                          onChange={(e) => {
                                              handleStatus(e);
                                          }}
                                          height={22}
                                          width={44}
                                          offColor="#ee5253"
                                          className="float-left"
                                      />
                                      <label
                                        className="form-check-label pointer-cursor ml-2"
                                        htmlFor="status"
                                      >
                                        {_t(t("Status"))}
                                      </label>
                                    </div>
                                    <div className="form-group mt-5 pb-2">
                                      <div className="col-lg-12">
                                        <button
                                          className="btn btn-primary px-3 rounded"
                                          type="submit"
                                        >
                                          {_t(t("Update"))}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>,
                              // end here
                      ]}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default FoodItemEdit
