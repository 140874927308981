import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";


//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";

const FoodItemReport = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { deptTagForSearch } = useContext(RestaurantContext);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();
  //settle order
  const [generatedReport, setGeneratedReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()
  const [deptTagForSearchObj, setDeptTagForSearchObj] = useState({});

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
  
  }, [generalSettings]);

  useEffect(() => {
    if (deptTagForSearch && deptTagForSearch.length) {
      const obj = {}
      for(const deptTag of deptTagForSearch) {
        obj[deptTag.id] = deptTag
      }
      setDeptTagForSearchObj(obj)
    }
  }, [deptTagForSearch])

  //get BranchWise reports filter
  const getBranchWiseSelected = async() => {
    setReportData(null);
    setGeneratedReport(false);
    setLoading(true);

    const url = BASE_URL + "/settings/get-food-item";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
        setReportData(res.data[1]);

        setLoading(false);
        console.log("Component ", componentRef);
        setGeneratedReport(true);
        componentRef.current.handleDownload();
    } catch (error) {
        
        setLoading(false)
    }
 
  };
 
  return (
    <>
      <Helmet>
        <title>{_t(t("Food Item Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Food Item Report"
        sheet="sheet 1"
        buttonText="Food Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="7" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Food Item Report
                                        </h3>
                                       
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("S/L"))}
                                    </th>
            
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Item Code"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Sku"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Name"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Menu"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Group"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Cost Price"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Status"))}
                                    </th>
                                    {/*<th
                                        scope="col"
                                    >
                                        {_t(t("Property Item"))}
                                    </th>
                                    <th
                                        scope="col"
                                    >
                                        {_t(t("Property Price"))}
                                    </th>*/}
                                    
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                               {
                                reportData.map((item,i)=>{
                                    return(
                                      <tr key={i}>
                                       <td>{i+1}</td>
                                       <td
                                        scope="row"
                                        >
                                        {item.id}
                                       </td>
                                       <td>{item.sku ? item.sku : "-"}</td>
                                       <td >
                                        {item.name}
                                        </td>
                                       <td>
                                       {item.menu_ids ?
                                        JSON.parse(item.menu_ids).map((menuId, i, menus) => (
                                          <>
                                            {deptTagForSearchObj[menuId] ? 
                                            <span>
                                              {deptTagForSearchObj[menuId].name}
                                              {i!==menus.length-1 ? ', ': ''}
                                            </span>
                                            : ''}
                                          </>
                                        ))
                                        : '-'}
                                       </td>
                                       <td>
                                        { item.groups && item.groups.length ?
                                            item.groups.map((group_item, i) => (
                                            group_item ? 
                                                <span>
                                                {group_item[0]?.name}
                                                { i!== item.groups.length-1 ? ', ' : ''}
                                                </span>
                                                : ""
                                                ))
                                            : "-" 
                                         }
                                        </td>
                                        <td>{item.has_variation === "1" ? item.cost_price : "-"}</td>
                                        <td>{item.item_status ? "On" : "Off"}</td>
                                      </tr>
                                    )
                                 })
                               }
                               
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div> */}
                    
                    {/* spin loading ends */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                    {/* Loading effect */}
                    
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Food Item Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                        
                          <div className="col-3 text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Print Food Item Report
                            </button>
                          </div>
                        </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default FoodItemReport;
