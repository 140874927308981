import React, { useState, useContext, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv';
//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { FoodContext } from "../../../../../contexts/Food";

const PropertyCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //property group
    propertyGroupList,
    setPropertyGroupList,
    setPaginatedPropertyGroup,
    propertyGroupForSearch,
    setPropertyGroupForSearch,

    //pagination
    dataPaginating,
  } = useContext(FoodContext);

  // States hook here
  //new property group
  let [newPropertyGroup, setNewPropertyGroup] = useState({
    name: "",
    sorting: 0,
    edit: false,
    editSlug: null,
    uploading: false,
  });

  //csv
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'Food-item',
  };
  const csvExporter = new ExportToCsv(options);
  const [uploadCsv,setUploadCsv] = useState(null);
  const [uploadCsvModal,setUploadCsvModal] = useState(false);

  //search result
  let [searchedPropertyGroup, setSearchedPropertyGroup] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(() => {}, []);

  //set name hook
  const handleSetNewPropertyGroup = (e) => {
    setNewPropertyGroup({
      ...newPropertyGroup,
      [e.target.name]: e.target.value,
    });
  };

  //Save New variation
  const handleSaveNewPropertyGroup = (e) => {
    e.preventDefault();
    setNewPropertyGroup({
      ...newPropertyGroup,
      uploading: true,
    });
    const propertyGroupUrl = BASE_URL + `/settings/new-property-group`;
    let formData = new FormData();
    formData.append("name", newPropertyGroup.name);
    formData.append("sorting", newPropertyGroup.sorting);
    return axios
      .post(propertyGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewPropertyGroup({
          name: "",
          sorting: 0,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setPropertyGroupList(res.data[0]);
        setPropertyGroupForSearch(res.data[1]);
        setSearchedPropertyGroup({
          ...searchedPropertyGroup,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Property group has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewPropertyGroup({
          ...newPropertyGroup,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A property group already exists with this name") {
                toast.error(
                  `${_t(t("A property group already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  //set edit true & values
  const handleSetEdit = (slug) => {
    let variation = propertyGroupForSearch.filter((item) => {
      return item.slug === slug;
    });
    setNewPropertyGroup({
      ...newPropertyGroup,
      name: variation[0].name,
      sorting: variation[0].sorting,
      editSlug: variation[0].slug,
      edit: true,
    });
  };

  //update property group
  const handleUpdatePropertyGroup = (e) => {
    e.preventDefault();
    setNewPropertyGroup({
      ...newPropertyGroup,
      uploading: true,
    });
    const propertyGroupUrl = BASE_URL + `/settings/update-property-group`;
    let formData = new FormData();
    formData.append("name", newPropertyGroup.name);
    formData.append("sorting", newPropertyGroup.sorting);
    formData.append("editSlug", newPropertyGroup.editSlug);
    return axios
      .post(propertyGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewPropertyGroup({
          name: "",
          sorting: 0,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setPropertyGroupList(res.data[0]);
        setPropertyGroupForSearch(res.data[1]);
        setSearchedPropertyGroup({
          ...searchedPropertyGroup,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Property group has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewPropertyGroup({
          ...newPropertyGroup,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A property group already exists with this name") {
                toast.error(
                  `${_t(t("A property group already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  
  function isCSV(contents) {
    // Basic check if it's a CSV file by checking if it contains a comma
    const rows = contents.split('\n');

    // Extract the header row (assuming it's the first row)
    const headerRow = rows[0];

    // Split the header row into individual header values
    const headers = headerRow.split(',');
    return contents.includes(',') && contents.includes('\n') && (headers.length === 3);
  }

  //csv
  const handlePropertyGroupCsv = (type) =>{
    setLoading(true);
    const url = BASE_URL + `/settings/get-properties-csv/${type} `;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        csvExporter.generateCsv(res.data);
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0]);
       //fileReader.readAsBinaryString(e.target.files[0]);
       fileReader.onload = (e) => {
         const contents = e.target.result;
       
        if(isCSV(contents)){
          const startIndex = 1; // Start index
          const lines = contents.trim().split('\n').slice(startIndex);
          const parsedData = lines.map((line)=>{
            const values = line.split(',').map(value => value.replace(/["/\r\n]/g, ''));
            return {
              id : values[0],
              name : values[1],
              sorting : values[2],
            }
          })
          setUploadCsv(parsedData);
        }else {  
          if (document.getElementById('uploadFile')) {
            document.getElementById('uploadFile').value = '';
          }
          setUploadCsv(null)
          setUploadCsvModal(false)
          toast.error("Please upload only property group csv file", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }); 
          
        } 
      
      };
     
   }; 
  }

  const handlePropertyGroupCsvSubmit = (e) => {
    e.preventDefault();
    
    const url = BASE_URL +"/settings/upload-properties-csv";
    let formData = {
      type : "group",
      items : uploadCsv
    }
    if(!uploadCsv || uploadCsv.length === 0){
      toast.error(`${_t(t("Please upload file"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      return false
    }
    
    setLoading(true);
    return axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (document.getElementById('uploadFile')) {
          document.getElementById('uploadFile').value = '';
        }
        setUploadCsv(null);
        setUploadCsvModal(false)
        setLoading(false);
        toast.success(`${_t(t("Property group file upload successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        
      })
      .catch(() => {
        setLoading(false);
        setUploadCsv(null);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  }

  //search property group here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedPropertyGroup({ ...searchedPropertyGroup, searched: false });
    } else {
      let searchedList = propertyGroupForSearch.filter((item) => {
        let lowerCaseItemName = item.name.toLowerCase();
        return lowerCaseItemName.includes(searchInput);
      });
      setSearchedPropertyGroup({
        ...searchedPropertyGroup,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of property group
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleDeletePropertyGroup(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete variation here
  const handleDeletePropertyGroup = (slug) => {
    setLoading(true);
    const propertyGroupUrl =
      BASE_URL + `/settings/delete-property-group/${slug}`;
    return axios
      .get(propertyGroupUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPropertyGroupList(res.data[0]);
        setPropertyGroupForSearch(res.data[1]);
        setSearchedPropertyGroup({
          ...searchedPropertyGroup,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(
          `${_t(t("Property group has been deleted successfully"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Property groups"))}</title>
      </Helmet>

      {/*upload csv Modal*/}
      <div
        className={`modal fade ${
          uploadCsvModal !== false ? "showing" : ""
        }`}
        id="addReason"
        aria-hidden="true"
      >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header align-items-center">
            <div className="fk-sm-card__content"></div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                if (document.getElementById('uploadFile')) {
                  document.getElementById('uploadFile').value = '';
                }
                setUploadCsv(null)
                setUploadCsvModal(false)
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
              <form onSubmit={handlePropertyGroupCsvSubmit} autoComplete="off">
                <div>
                  <label htmlFor="name" className="form-label">
                    {_t(t("Upload File"))}{" "}
                    <small style={{ color: "red" }}>*</small>
                  </label>
                  <br/>
                  <input
                    type={"file"}
                    id="uploadFile"
                    name="uploadFile"
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                 </div>
                 <button
                 type="submit"
                 className="btn btn-primary xsm-text text-uppercase px-3 py-2 my-4 rounded"
                 >
                   {_t(t("Submit"))}
                 </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add modal */}
      <div className="modal fade" id="addvariation" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newPropertyGroup.edit
                    ? _t(t("Add new property group"))
                    : _t(t("Update property group"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newPropertyGroup.uploading === false ? (
                <div key="fragment-property-group-1">
                  <form
                    onSubmit={
                      !newPropertyGroup.edit
                        ? handleSaveNewPropertyGroup
                        : handleUpdatePropertyGroup
                    }
                    autoComplete="off"
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Addon, spice level, sauce level"
                        value={newPropertyGroup.name || ""}
                        required
                        onChange={handleSetNewPropertyGroup}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="sorting" className="form-label">
                        {_t(t("Sorting"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="number"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", ".","ArrowUp", "ArrowDown"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="sorting"
                        name="sorting"
                        placeholder="e.g. 0,1"
                        value={newPropertyGroup.sorting}
                        onChange={handleSetNewPropertyGroup}
                        required
                      />
                    </div>

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3 ">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newPropertyGroup.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newPropertyGroup.edit
                            ? _t(t("Save"))
                            : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newPropertyGroup.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb d-flex justify-content-between align-items-center">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedPropertyGroup.searched
                                    ? _t(t("Property Group List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                              <div>
                                <button title="Download property group in excel" className="btn btn-success btn-sm text-uppercase sm-text mr-2 rounded" onClick={()=>handlePropertyGroupCsv("type")} >Export Csv</button>
                                <button title="Upload property group in excel" className="btn btn-primary btn-sm text-uppercase sm-text mr-2 rounded" onClick={()=>setUploadCsvModal(true)}>Import Csv</button>
                              </div>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addvariation"
                                  onClick={() => {
                                    setNewPropertyGroup({
                                      ...newPropertyGroup,
                                      name: "",
                                      sorting: 0,
                                      edit: false,
                                      editSlug: null,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Sorting"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedPropertyGroup.searched
                                ? [
                                    propertyGroupList && [
                                      propertyGroupList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        propertyGroupList.data.map(
                                          (item, index) => {
                                           
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (propertyGroupList.current_page -
                                                      1) *
                                                      propertyGroupList.per_page}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.sorting}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu"> */}
                                                      {/* <NavLink
                                                        to={`/dashboard/manage/food/properties/${item.slug}`}
                                                        className="dropdown-item sm-text text-capitalize"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-plus"></i>
                                                        </span>
                                                        {_t(t("Items"))}
                                                      </NavLink> */}
                                                      {/* <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(
                                                            item.slug
                                                          )
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addvariation"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.slug
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button> */}
                                                    {/* </div> */}
                                                  {/* </div> */}
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(
                                                      item.slug
                                                    )
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addvariation"                                               
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedPropertyGroup && [
                                      searchedPropertyGroup.list.length ===
                                      0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedPropertyGroup.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (propertyGroupList.current_page -
                                                      1) *
                                                      propertyGroupList.per_page}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.sorting}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      {
                                                        /*
                                                    
                                                      <NavLink
                                                        to={`/dashboard/manage/food/properties/item`}
                                                        className="dropdown-item sm-text text-capitalize"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-plus"></i>
                                                        </span>
                                                        {_t(t("Items"))}
                                                      </NavLink>
                                                      */}
                                                      
                                                    <button
                                                    className="btn btn-primary ml-2 rounded-sm del-btn"
                                                    onClick={() =>
                                                      handleSetEdit(
                                                        item.slug
                                                      )
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#addvariation"                                               
                                                    >                                                    
                                                      <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                  </button>
                                                    <button
                                                    className="btn btn-danger ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                    >                                                    
                                                      <MdDelete className="del-icon" />                                                    
                                                  </button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ),
                                      ],
                                    ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newPropertyGroup.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedPropertyGroup.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                propertyGroupList,
                                setPaginatedPropertyGroup
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(propertyGroupList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() =>
                                    setSearchedPropertyGroup({
                                      ...searchedPropertyGroup,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedPropertyGroup,
                                    propertyGroupForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default PropertyCrud;
